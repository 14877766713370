import { IGroup, ITradeInfoState } from "redux/interfaces/ITradeInfo";
import { ISymbolInfo, SymbolsInfoResponse } from "@/services/trade/symbols";
import { uniqueBy } from "@/utils/index";
import { getLocationParam } from "@/utils/url";

const initialState: ITradeInfoState = {
    activeSymbolId: null,
    isActiveSymbolFromURL: false,
    internalSymbolId: null,
    activeOrderId: null,
    activeSymbolPoint: 0.001,
    isSymbolsDataLoaded: false,
    isQuoteDataLoaded: false,
    isAnonymousMode: false,
    groups: [],
    activeGroupName: "",
    symbols: [],
    symbolsInfo: [],
    symbolsInfoById: {},
    search: "",
    // it was included here because redux-persist lib caches this value for account reducer
    isAccountSocketDataLoaded: false,
};

export enum TradeInfoActionTypes {
    SET_ACTIVE_SYMBOL = "trade/SET_ACTIVE_SYMBOL",
    SET_ACTIVE_SYMBOL_FROM_URL = "trade/SET_ACTIVE_SYMBOL_FROM_URL",
    SET_ACTIVE_ORDER = "trade/SET_ACTIVE_ORDER",
    SYMBOLS_DATA_LOADED = "trade/SYMBOLS_DATA_LOADED",
    QUOTE_DATA_LOADED = "trade/QUOTE_DATA_LOADED",
    SET_SEARCH = "trade/SET_SEARCH",
    SET_ACTIVE_GROUP_NAME = "trade/SET_ACTIVE_GROUP_NAME",
    SET_ACCOUNT_DATA_LOADED = "SET_ACCOUNT_DATA_LOADED",
}

export interface ISetActiveSymbolAction {
    type: typeof TradeInfoActionTypes.SET_ACTIVE_SYMBOL;
    id: string;
    point: number;
    internalSymbolId?: string;
}

export interface ISetActiveSymbolFromURLAction {
    type: typeof TradeInfoActionTypes.SET_ACTIVE_SYMBOL_FROM_URL;
    isActive: boolean;
}

export interface ISetActiveOrderAction {
    type: typeof TradeInfoActionTypes.SET_ACTIVE_ORDER;
    id: number;
}

export interface ISymbolsDataLoadedAction {
    type: typeof TradeInfoActionTypes.SYMBOLS_DATA_LOADED;
    isAnonymousMode: boolean;
    symbolsInfoById: SymbolsInfoResponse;
    categories: string[];
}

export interface IQuoteDataLoadedAction {
    type: typeof TradeInfoActionTypes.QUOTE_DATA_LOADED;
}

export interface IAccountDataLoadedAction {
    type: typeof TradeInfoActionTypes.SET_ACCOUNT_DATA_LOADED;
}

export interface ISetSearchAction {
    type: typeof TradeInfoActionTypes.SET_SEARCH;
    search: string;
}

export interface ISetActiveGroupNameAction {
    type: typeof TradeInfoActionTypes.SET_ACTIVE_GROUP_NAME;
    activeGroupName: string;
}

export type TradeInfoActions =
    | ISetActiveOrderAction
    | ISetActiveSymbolAction
    | ISetActiveSymbolFromURLAction
    | ISymbolsDataLoadedAction
    | IQuoteDataLoadedAction
    | ISetSearchAction
    | ISetActiveGroupNameAction
    | IAccountDataLoadedAction;

export const tradeInfoReducer = (state = initialState, action: TradeInfoActions): ITradeInfoState => {
    const { type } = action;

    switch (type) {
        case TradeInfoActionTypes.SET_ACTIVE_SYMBOL: {
            const { id, point, internalSymbolId } = action as ISetActiveSymbolAction;

            if (id === state.activeSymbolId) {
                return state;
            }

            return {
                ...state,
                activeSymbolId: id,
                activeSymbolPoint: point,
                internalSymbolId,
            };
        }

        case TradeInfoActionTypes.SET_ACTIVE_ORDER: {
            const { id } = action as ISetActiveOrderAction;

            if (id === state.activeOrderId) {
                return state;
            }

            return {
                ...state,
                activeOrderId: id,
            };
        }

        case TradeInfoActionTypes.SYMBOLS_DATA_LOADED: {
            const { isAnonymousMode, symbolsInfoById, categories } = action as ISymbolsDataLoadedAction;

            if (symbolsInfoById.isFailed) {
                throw new Error("Cannot fetch symbols");
            }

            Object.entries(symbolsInfoById).forEach(entry => {
                const symbolId = entry[0];
                const symbol: Partial<ISymbolInfo> = entry[1] || {};

                symbol.id = symbolId;
                symbol.quote.midnightPrice = symbol.quote.midnightPrice || null;

                // console.log("[DEBUG]: symbol", symbol);
            });

            const symbolsInfo = Object.values(symbolsInfoById);

            const symbols = Object.keys(symbolsInfoById);

            const groups = uniqueBy(
                symbolsInfo.map(({ group }) => group),
                (group1, group2) => group1.name === group2.name
            );

            const groupsSorted = categories
                .reduce((acc, category) => {
                    const group = groups.find(g => g.name === category);
                    if (group) {
                        acc = [...acc, group];
                    }
                    return acc;
                }, [] as IGroup[])
                .filter(g => g);

            let activeSymbolId = symbols[0];
            let activeSymbolPoint = symbolsInfo[0]?.point;
            let isActiveSymbolFromURL = false;
            let activeGroupName = state.activeGroupName;

            if (symbols.includes("EURUSD")) {
                // set default EURUSD asset
                const index = symbols.indexOf("EURUSD");

                activeSymbolId = symbols[index];
                activeSymbolPoint = symbolsInfo[index]?.point;
            }

            const activeSymbolIdParam = getLocationParam("asset");

            if (activeSymbolIdParam) {
                const matchingSymbol = symbolsInfo.find(
                    s =>
                        (s.ex.displayName &&
                            s.ex.displayName.toLowerCase() === activeSymbolIdParam.toLowerCase()) ||
                        s.id.toLowerCase() === activeSymbolIdParam.toLowerCase()
                );

                if (matchingSymbol) {
                    activeSymbolId = matchingSymbol.id;
                    activeSymbolPoint = symbolsInfoById[matchingSymbol.id].point;
                    isActiveSymbolFromURL = true;
                    activeGroupName = matchingSymbol.group.name;
                }
            }

            const activeGroupNameParam = getLocationParam("category");

            if (activeGroupNameParam) {
                if (activeGroupNameParam.toLowerCase() === "favourites") {
                    activeGroupName = "Favourites";
                } else {
                    const matchingGroup = groupsSorted.find(
                        g => g.name.toLowerCase() === activeGroupNameParam.toLowerCase()
                    );
                    if (matchingGroup) {
                        activeGroupName = matchingGroup.name;
                    }
                }
            }

            return {
                ...state,
                isAnonymousMode,
                groups: groupsSorted,
                symbols,
                symbolsInfo,
                symbolsInfoById,
                activeSymbolId,
                activeSymbolPoint,
                isActiveSymbolFromURL,
                activeGroupName,
                isSymbolsDataLoaded: true,
            };
        }

        case TradeInfoActionTypes.QUOTE_DATA_LOADED: {
            return {
                ...state,
                isQuoteDataLoaded: true,
            };
        }

        case TradeInfoActionTypes.SET_ACCOUNT_DATA_LOADED: {
            return {
                ...state,
                isAccountSocketDataLoaded: true,
            };
        }

        case TradeInfoActionTypes.SET_SEARCH: {
            const { search } = action as ISetSearchAction;

            return {
                ...state,
                search,
            };
        }

        case TradeInfoActionTypes.SET_ACTIVE_GROUP_NAME: {
            const { activeGroupName } = action as ISetActiveGroupNameAction;

            return {
                ...state,
                activeGroupName,
            };
        }

        case TradeInfoActionTypes.SET_ACTIVE_SYMBOL_FROM_URL: {
            const { isActive } = action as ISetActiveSymbolFromURLAction;
            return {
                ...state,
                isActiveSymbolFromURL: isActive,
            };
        }
    }

    return state;
};
