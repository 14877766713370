import getConfig from "next/config";
import { useEffect } from "react";

const getChartLicenseExpirationDate = async (): Promise<string | null> => {
    try {
        const response = await fetch("/ChartCore/js/6.4.5/version/prochart.version.json.js");
        if (response.ok) {
            const chartVersionInfo = await response.text();
            const parsedInfo = JSON.parse(chartVersionInfo);
            return parsedInfo.expiration as string;
        }
        throw new Error();
    } catch (error) {
        return null;
    }
};

const isUpdateLicenseTime = (dateString: string, daysAhead = 4): boolean => {
    if (!dateString) return false;

    const [month, day, year] = dateString.split(".");
    const licenseDate = new Date(`${year}-${month}-${day}`);

    if (isNaN(licenseDate.getTime())) return false;

    const currentDate = new Date();
    const daysLater = new Date(currentDate);
    daysLater.setDate(currentDate.getDate() + daysAhead);

    return licenseDate <= daysLater;
};

const sendNotificationToSlack = async (message: string) => {
    try {
        const response = await fetch("api/slack", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ text: message }),
        });

        if (response.ok) {
            console.log("Notification was sent successfully in slack.");
        }
    } catch (error) {
        console.error(error);
    }
};

const useChartLicenseReminder = () => {
    useEffect(() => {
        const { publicRuntimeConfig } = getConfig();
        const env = publicRuntimeConfig["envMode"];
        const today = new Date().toISOString().split("T")[0];

        const validateChartLicenseDate = async () => {
            const expDate = await getChartLicenseExpirationDate();

            if (isUpdateLicenseTime(expDate)) {
                const lastSentDate = localStorage.getItem("slackNotificationDate");

                if (lastSentDate === today) return;

                const [month, day, year] = expDate.split(".");

                await sendNotificationToSlack(
                    `📢 Reminder: Your chart license will expire on ${day}-${month}-${year}. Please renew it to avoid interruption.`
                );
                localStorage.setItem("slackNotificationDate", today);
            }
        };

        if (env !== "production") {
            validateChartLicenseDate();
        }
    }, []);
};

export default useChartLicenseReminder;
